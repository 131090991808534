<template>
  <div class="medical-records-container">
    <div class="scroll-container">
        <div class="main-container">
            <ul class="tab-container">
                <li class="tab" v-for="(item,index,key) in productList" :key="item.id +'_' +key" :class="{active: curTab === item.id}" @click="handleToggleTab(item)">{{ item.name }}</li>
                <!-- <li class="tab" :class="{active: curTab === 'b'}" @click="handleToggleTab('b')">心血管疾病</li> -->
                <!-- <li class="tab" :class="{active: curTab === 'c'}" @click="handleToggleTab('c')">甲状腺疾病</li> -->
                <!-- <li class="tab" :class="{active: curTab === 'd'}" @click="handleToggleTab('d')">消化系统疾病</li> -->
            </ul>
            <div class="swiper-container">
                <div class="swiper-wrapper">
                <div class="swiper-slide content" v-if="list.length">
                    <div class="swiper-slide-content" v-for="(item) in list" :key="item.id">
                        <img :src="item.titleImg" alt="" class="left-img">
                            <div class="right">
                            <div class="title">
                                <h3>
                                <img v-show="item.icon != null" :src="item.icon" class="icon" alt="">
                                {{item.title}}
                                </h3>
                            </div>
                            <div class="des-wrapper">
                                <p class="des">{{item.info}}</p>
                                <p class="des bottom0" v-if="item.releaseTime">时间：{{item.releaseTime}}</p>
                                <p class="des bottom0" v-if="item.lecturer">讲者：{{item.lecturer}}</p>
                                <span class="more">
                                <span @click.stop="goDetail(item.id)" class="line">-</span>
                                <span @click.stop="goDetail(item.id)">了解更多</span>
                                </span>
                            </div>
                            </div>
                            <span v-show="isRight" class="item-arrow arrow-right" @click="nextSlider"></span>
                            <span v-show="isLeft" class="item-arrow arrow-left" @click="prevSlider"></span>
                    </div>
                
                </div>
                <div class="swiper-slide content empty" >
                    暂无数据
                </div>
                
                    <!--进度条-->
                    <div class="item-progress-box" v-show="list.length">
                        <div class="item-progress-bar">
                            <div class="item-progress-active"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import indexApi from '@/api/index'
// import Swiper from 'swiper';
export default {
  name: "medical-records",
  data() {
    return {
      curTab: '',
      img: './images/b.png',
      title: '康忻病例分享',
      des: 'β受体阻滞剂是临床常用的心血管治疗药物，但患者的疗效及反应各有差异。近年来，药物基因多态性问题日益受到关注。精准医疗无疑是高血压治疗实现突破的重要出路。',
      mySwiper: null,
      list: [],

      productList: [],

      page: 1,
      pageSize: 1,
      realmId: '',
      isShowImg: false,

      isLeft: false,
      isRight: true
    }
  },
  created() {
    //this.getPerspectiveList();
    this.getProductList();
  },
  mounted() {
    // this.mySwiper = new Swiper('.swiper-container', {});
  },
  methods: {
    handleToggleTab(item) {
      this.page = 1;
      this.curTab = item.id;
      this.realmId  = item.id;
      this.getPerspectiveList();
      if(item && item.name && item.name.indexOf('心血管') != -1){
        this.isShowImg = true;
      } else {
        this.isShowImg = false;
      }
    },

    // 左右切换
    nextSlider() {
      // console.log(this.mySwiper,'this.mySwiper')
      // this.mySwiper.slideNext();
      this.page = this.page + 1;
      this.getPerspectiveList();
    },

    prevSlider() {
      if(this.page==0){
        this.isLeft = false;
          this.page = 0;
        this.getPerspectiveList();
      } else{
        this.page = this.page - 1;
        this.isLeft = true;
        this.getPerspectiveList();
      }
      
      // this.mySwiper.slidePrev();
    },

    // 获取临床视角列表
    getPerspectiveList() {
      let params = {
        "condition": {
          "realmId": this.realmId,//领域ID
          "keywords":""
        },
        "page": this.page,//页码
        "size": this.pageSize//页大小
      };
      indexApi.getPerspectiveList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data && res.data.list;
          let page = res && res.data && res.data.page;
          let pageNums = res && res.data && res.data.pageNums
          // this.list = [];
         this.list = data;
         

          let proportion = (page / pageNums) * 100
          document.getElementsByClassName('item-progress-active')[0].style.width = proportion + '%';
          if(this.page != 1) {
            this.isLeft = true;
          } else{
            this.isLeft = false;
          }

          if(page < pageNums){
              this.isRight = true;
          } else {
              this.isRight = false;
          }
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },

    // 跳转详情
    goDetail(id) {
      // this.$router.push({
      //   name: 'medicalRecordsDetail',
      //   params:{ id: id}
      // })
		let that = this;
		let button_params = {
			button: "了解更多",
			buttonContent: "",
			dataId: id,
			type: parseInt(4)
		}
		that.$point.buttonClickFn(button_params);
        that.$tools.windowOpenByRouterParmas.call(that,{
          name: 'medicalRecordsDetail',
          params:{ id: id}
        })
    },

    // 获取领域列表 
    getProductList() {
      let params = {};
      indexApi.getProductList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data;
          this.productList = data;
          this.curTab = this.productList && this.productList && this.productList[0].id;
          this.realmId = this.productList && this.productList && this.productList[0].id;
          this.getPerspectiveList();
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },
  }
}
</script>

<style scoped lang="scss">
// .swiper-container {
//     width: 600px;
//     height: 300px;
// }

.scroll-container {
    width:100%;
    max-width: 100%;
 }
.medical-records-container {
  background-image: url("~./images/bg.jpg");
  background-size: cover;
  line-height: 1;
}
.tab-container {
  background: rgba(255,255,255, 0.75);
  font-size: .18rem;
  color: #333;
  display: flex;
  align-items: center;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
  height: .7rem;
  line-height: .7rem;
  margin-top: .6rem;
  li {
    width: 2.2rem;
    position: relative;
    text-align: center;
    cursor: pointer;
    z-index: 3;
    &.active {
      color: #fff;
      &:before {
        content: '';
        display: block;
        width: 2.2rem;
        height: .88rem;
        background: url("~./images/active-tab.png") center center / 100% auto no-repeat;
        left: 0;
        bottom: 0;
        position: absolute;
        z-index: -1;
      }
    }
  }
}
.content {
  
  background: url("~./images/content-bg.png") left top /100% 100% no-repeat rgba(255,255,255, 0.75);
  border-bottom-right-radius: .8rem;
  border-bottom-left-radius: .2rem;
  height: 6.6rem;
  overflow: auto;
  &.empty{
      display: flex;
      align-items: center;
      justify-content: center;
  }
 
  .swiper-slide-content{
    height: 6.6rem;
    padding: 1rem .6rem 1.56rem;
    display: flex;
    align-items: flex-start;
    position: relative;
  }
  .left-img {
    flex: none;
    display: block;
    width: 7.2rem;
    height: 4.04rem;
    border-radius: .08rem .8rem .08rem .8rem;
  }
  .right {
    flex: auto;
    .title {
      display: flex;
      align-items: center;
      font-size: .26rem;
      color: #1a1a1a;
      line-height: 1;
      padding: .56rem 0 .25rem .59rem;
      border-bottom: 1px solid #eb3c96;
      margin-bottom: .32rem;
      position: relative;
      h3{
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        height: .53rem;
        line-height: .46rem;
      }
      .icon {
        display: inline-block;
        width: .61rem;
        height: .54rem;
        margin-left: .15rem;
        vertical-align: middle;
      }

    }
    .des-wrapper {
      padding-left: .59rem;
      padding-right: 1.6rem;
      .des {
        line-height: .3rem;
        font-size: .16rem;
        color: #666;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: .34rem;
        &.bottom0{
             margin-bottom: .1rem;
        }
      }
      .more {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        font-size: .16rem;
        color: #666;
        .line {
          display: block;
          width: .48rem;
          height: .48rem;
          text-align: center;
          line-height: .48rem;
          background: #f0f0f0;
          border-radius: 100%;
          color: #999;
          margin-right: .1rem;
          cursor: pointer;
        }
      }
    }

  }
  .item-arrow {
    &:hover{
      background: url("~./images/arrow-right.png") center center / .13rem .22rem no-repeat #EB3C96;
    }
  }
  .arrow-right {
      position: absolute;
      right: .3rem;
      top: 2.6rem;
      width: .52rem;
      height: .52rem;
      border-radius: 100%;
      cursor: pointer;
      background: url("~./images/arrow-right.png") center center / .13rem .22rem no-repeat #503291;
      box-shadow: .1rem 0 .2rem 0 rgba(0, 0, 0, 0.1);
    }

    .arrow-left{
      position: absolute;
      left: .3rem;
      top: 2.6rem;
      width: .52rem;
      height: .52rem;
      border-radius: 100%;
      cursor: pointer;
      background: url("~./images/arrow-right.png") center center / .13rem .22rem no-repeat #503291;
      box-shadow: .1rem 0 .2rem 0 rgba(0, 0, 0, 0.1);
      transform: rotate(180deg);
    }
}
.item-progress-box{
    width: 100%;
    box-sizing: border-box;
    padding:0 0.6rem;
    position:absolute;
    left: 0;
    bottom: 1rem;
}
.item-progress-bar{
      width: 100%;
      height: .02rem;
      background: #EBEBEB;
      overflow: hidden;

      .item-progress-active{
        width: 20%;
        height: .2rem;
        background: #EB3C96;
      }
    }
</style>
